import { createContext, useMemo, useState, useEffect } from "react";
var onList = [];
var onAnyList = [];
export default function useLocalStorage(type) {
    var storageType = useState(window["".concat(type, "Storage")])[0];
    useEffect(function () {
        var handleStorage = function (event) {
            var key = event.key, oldValue = event.oldValue, newValue = event.newValue;
            if (key && key.match(/^(\$\$)(.*)(_data)$/))
                return;
            if (oldValue === null && newValue !== null) {
                onList
                    .filter(function (obj) { return obj.type === "init"; })
                    .forEach(function (obj) { return obj.callback(key); });
                onAnyList.forEach(function (obj) { return obj.callback("init", key); });
            }
            else if (oldValue !== null && newValue !== null) {
                onList
                    .filter(function (obj) { return obj.type === "set"; })
                    .forEach(function (obj) { return obj.callback(key); });
                onAnyList.forEach(function (obj) { return obj.callback("set", key); });
            }
            else if (oldValue === null && newValue !== null) {
                onList
                    .filter(function (obj) { return obj.type === "remove"; })
                    .forEach(function (obj) { return obj.callback(key); });
                onAnyList.forEach(function (obj) { return obj.callback("remove", key); });
            }
        };
        window.addEventListener("storage", handleStorage);
        return function () { return window.removeEventListener("storage", handleStorage); };
    });
    return useMemo(function () {
        return {
            init: function (key, data) {
                var type = typeof data;
                if (type === "object") {
                    data = JSON.stringify(data);
                }
                storageType.setItem(key, data);
                storageType.setItem("$$".concat(key, "_data"), type);
                onList
                    .filter(function (obj) { return obj.type === "init"; })
                    .forEach(function (obj) { return obj.callback(key); });
                onAnyList.forEach(function (obj) { return obj.callback("init", key); });
            },
            set: function (key, data) {
                var type = typeof data;
                if (type === "object") {
                    data = JSON.stringify(data);
                }
                storageType.setItem(key, data);
                storageType.setItem("$$".concat(key, "_data"), type);
                onList
                    .filter(function (obj) { return obj.type === "set"; })
                    .forEach(function (obj) { return obj.callback(key); });
                onAnyList.forEach(function (obj) { return obj.callback("set", key); });
            },
            get: function (key) {
                var type = storageType.getItem("$$".concat(key, "_data"));
                var data = storageType.getItem(key);
                onList
                    .filter(function (obj) { return obj.type === "get"; })
                    .forEach(function (obj) { return obj.callback(key); });
                onAnyList.forEach(function (obj) { return obj.callback("get", key); });
                switch (type) {
                    case "object":
                        return JSON.parse(data);
                    case "number":
                        return parseFloat(data);
                    case "boolean":
                        return data === "true";
                    case "undefined":
                        return undefined;
                    default:
                        return data;
                }
            },
            remove: function (key) {
                storageType.removeItem(key);
                storageType.removeItem("$$".concat(key, "_data"));
                onList
                    .filter(function (obj) { return obj.type === "remove"; })
                    .forEach(function (obj) { return obj.callback(key); });
                onAnyList.forEach(function (obj) { return obj.callback("remove", key); });
            },
            clear: function () {
                storageType.clear();
                onList
                    .filter(function (obj) { return obj.type === "clear"; })
                    .forEach(function (obj) { return obj.callback(); });
                onAnyList.forEach(function (obj) { return obj.callback("clear"); });
            },
            on: function (event, func) {
                onList.push({ type: event, callback: func });
            },
            off: function (event, func) {
                var remove = onList.indexOf(onList.filter(function (e) { return e.type === event && e.callback === func; })[0]);
                if (remove >= 0)
                    onList.splice(remove, 1);
            },
        };
    }, [storageType]);
}
export var StorageContext = createContext(null);
